import React from "react";
import PropTypes from "prop-types";
import { Item, Divider } from "semantic-ui-react";
import shuffle from "../../utils/shuffle";

const ScheduleTalks = ({ speakers, tyler, kent }) => (
  <div>
    <ul style={{ fontSize: 16, lineHeight: "1.5" }}>
      <li>Registration at the conference will open at 7:30 am on June 19th.</li>
      <li>A light breakfast will be provided starting at 8:00 am.</li>
      <li>Conference presentations will begin at 9:00 am</li>
      <li>
        Snacks and beverages will be provided throughout the day and during
        breaks.
      </li>
      <li>
        We'll be releasing more details about the schedule, including lunch, as
        we nail down those details.
      </li>
    </ul>
    <p>
      While we're still working out the final schedule check out all of the
      awesome presentations you'll see at React Loop 2020!
    </p>
    <Divider />
    <div>
      <Item.Group divided>
        <Item>
          <Item />
          <Item.Image size="tiny" src={kent.image} />
          <Item.Content>
            <Item.Meta>{kent.name}</Item.Meta>
            <Item.Header>{kent.talk_title}</Item.Header>
            <Item.Description style={{ fontSize: "1.1em" }}>
              {kent.talk_description}
            </Item.Description>
          </Item.Content>
        </Item>
        {shuffle(speakers)
          .filter(s => !["kentcdodds", "tylermcginnis"].includes(s.twitter))
          .map((speaker, index) => {
            const { talk_title, talk_description, image, name } = speaker;
            return (
              <Item key={index}>
                <Item />
                <Item.Image size="tiny" src={image} />
                <Item.Content>
                  <Item.Meta>{name}</Item.Meta>
                  <Item.Header>{talk_title}</Item.Header>
                  <Item.Description style={{ fontSize: "1.1em" }}>
                    {talk_description}
                  </Item.Description>
                </Item.Content>
              </Item>
            );
          })}
        <Item>
          <Item />
          <Item.Image size="tiny" src={tyler.image} />
          <Item.Content>
            <Item.Meta>{tyler.name}</Item.Meta>
            <Item.Header>{tyler.talk_title}</Item.Header>
            <Item.Description style={{ fontSize: "1.1em" }}>
              {tyler.talk_description}
            </Item.Description>
          </Item.Content>
        </Item>
      </Item.Group>
    </div>
  </div>
);

ScheduleTalks.propTypes = {
  speakers: PropTypes.array,
  tyler: PropTypes.object,
  kent: PropTypes.object,
};

export default ScheduleTalks;
