import React from "react";
import PropTypes from "prop-types";
import { Item } from "semantic-ui-react";
import moment from "moment";

const ScheduleFull = ({ schedule }) => {
  return (
    <div>
      <Item.Group divided>
        {schedule.map((item, index) => {
          if (item.type === "presentation") {
            const {
              yt_video_id,
              talk_title,
              talk_description,
              name,
              image,
            } = item.speaker[0].data;
            return (
              <Item key={index}>
                <Item />
                <Item.Image
                  size="tiny"
                  src={image.localFiles[0].publicURL}
                />
                <Item.Content>
                  <Item.Meta>
                    {moment(item.time, "HH:mm").format("h:mm a")} - {name}
                  </Item.Meta>
                  <Item.Header>{talk_title}</Item.Header>
                  <Item.Description style={{ fontSize: "1.1em" }}>
                    {talk_description}
                    {yt_video_id && (
                      <a
                        style={{ marginLeft: 10 }}
                        href={`https://youtu.be/${yt_video_id}`}
                      >
                        Video
                      </a>
                    )}
                  </Item.Description>
                </Item.Content>
              </Item>
            );
          }

          return (
            <Item key={index}>
              <Item.Content>
                <Item.Meta>
                  {moment(item.time, "HH:mm").format("h:mm a")}
                </Item.Meta>
                <Item.Header>{item.title}</Item.Header>
                <Item.Description>{item.description}</Item.Description>
              </Item.Content>
            </Item>
          );
        })}
      </Item.Group>
    </div>
  );
};

ScheduleFull.propTypes = {
  schedule: PropTypes.array,
};

export default ScheduleFull;
