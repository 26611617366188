import React from "react";
import PropTypes from "prop-types";
import { Card, Icon, Image } from "semantic-ui-react";

const rand = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const Speaker = ({ speaker, featured }) => {
  return (
    <Card style={{ order: rand(1, 12) }}>
      <Image alt={speaker.name} src={speaker.image} size="medium" />
      <Card.Content>
        <Card.Header>{speaker.name}</Card.Header>
        <Card.Meta>
          <span
            className="date"
            dangerouslySetInnerHTML={{ __html: speaker.title }}
          />
        </Card.Meta>
      </Card.Content>
      {(speaker.github || speaker.twitter) && (
        <Card.Content extra textAlign="center">
          {speaker.github && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://www.github.com/${speaker.github}`}
            >
              <Icon size="large" name="github" />
            </a>
          )}
          {speaker.twitter && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://www.twitter.com/${speaker.twitter}`}
            >
              <Icon size="large" name="twitter" />
            </a>
          )}
        </Card.Content>
      )}
    </Card>
  );
};

Speaker.propTypes = {
  speaker: PropTypes.object,
  featured: PropTypes.bool,
};

export default Speaker;
