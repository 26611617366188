import React from "react";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/pageheader/PageHeader";
import useConfig from "../data-hooks/useConfig";
import { Segment } from "semantic-ui-react";

const Scholarship = () => {
  const { diversityTickets } = useConfig();
  return (
    <Layout page="home">
      <PageHeader
        className="home"
        headline="React Loop 2020 Scholarship Program"
        subline="Apply by February 14th, 2020."
      />
      <div style={{ margin: "0 auto 35px auto", width: "80%" }}>
        <p style={{ lineHeight: 1.6 }}>
          The React Loop 2020 Scholarship Program is aimed to help
          underrepresented groups in tech to attend and to lower the barriers
          for them. This includes but isn’t limited to: women, people of color,
          LGBTQIA+ people, and disabled people. Your privacy, details and
          answers will only be shared with the organizers of React Loop 2020.
          These tickets are sponsored by React Loop. This application is for one
          conference ticket only. Ticket provides access to the full day of
          presentations and all the features of a standard ticket to React Loop
          2020.
        </p>
        <p>
          The deadline for submission is February 14th, 2020. All recipients will be
          notified no later than March 2nd, 2020.
        </p>
        <p>
          Please fill out this form in English. Fields marked with * are
          required. Unfortunately we can not take care of those applications
          filled in a different language. Applications not written in English
          would be automatically rejected. We would like to avoid that so please
          keep it in mind when submitting your application.
        </p>
      </div>
      {diversityTickets ? (
        <div style={{ margin: "0 auto", width: 640 }}>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScxcV78J454zHYeKMRZJBwXJr14V_Hr-uOlfzeIDfUbYOCEVw/viewform?embedded=true"
            width="100%"
            height="1200"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="React Loop 2020 Scholarship"
          >
            Loading…
          </iframe>
        </div>
      ) : (
        <Segment style={{width: '80%', margin: '0 auto'}}>
          <p>The React Loop 2020 Scholarship Program is not currently available.</p>
        </Segment>
      )}
    </Layout>
  );
};

export default Scholarship;
