import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import {
  Image,
  Container,
  Icon,
  Menu,
  Responsive,
  Sidebar,
} from "semantic-ui-react";
import logo from "./logo.png";

const MobileContainer = ({ config, children }) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", hide);
    return () => document.removeEventListener("scroll", hide);
  }, []);

  const hide = () => setSidebarOpened(false);
  const handleToggle = () => setSidebarOpened(true);
  const addDimmerTouch = () =>
    document.querySelector(".pusher.dimmed").addEventListener("touchend", hide);

  const {
    videos,
    speakers,
    sponsors,
    tickets,
    schedule,
    venue,
    soldOut,
    links,
    workshop,
    diversityTickets,
  } = config;

  return (
    <div>
      <Responsive
        as={Sidebar.Pushable}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          className="mobile-nav"
          as={Menu}
          animation="overlay"
          onHide={hide}
          vertical
          fixed="top"
          direction="right"
          onShow={addDimmerTouch}
          visible={sidebarOpened}
        >
          <Menu.Menu position="right">
            <Menu.Item as={Link} onClick={hide} to="/">
              Home
            </Menu.Item>
            {/* {tickets && !soldOut && (
              <Menu.Item
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href={links.TICKET_SALES}
              >
                Buy Tickets
              </Menu.Item>
            )} */}
            {videos && (
              <Menu.Item as={Link} onClick={hide} to="/videos">
                Videos
              </Menu.Item>
            )}
            {speakers && (
              <Menu.Item as={Link} onClick={hide} to="/speakers">
                Speakers
              </Menu.Item>
            )}
            {schedule && (
              <Menu.Item as={Link} onClick={hide} to="/schedule">
                Schedule
              </Menu.Item>
            )}
            {workshop && (
              <Menu.Item as={Link} onClick={hide} to="/workshop">
                Workshop
              </Menu.Item>
            )}
            {venue && (
              <Menu.Item as={Link} onClick={hide} to="/venue">
                Venue
              </Menu.Item>
            )}
            {sponsors && (
              <Menu.Item as={Link} onClick={hide} to="/sponsors">
                Sponsors
              </Menu.Item>
            )}
            {diversityTickets && (
              <Menu.Item as={Link} onClick={hide} to="/scholarship">
                Scholarship
              </Menu.Item>
            )}
            <Menu.Item as={Link} onClick={hide} to="/conduct">
              Conduct
            </Menu.Item>
            {/* {tickets && !soldOut && (
              <Menu.Item
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href={links.TICKET_SALES}
              >
                Buy Tickets
              </Menu.Item>
            )} */}
          </Menu.Menu>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Container fluid>
            <Menu pointing size="large" fixed="top">
              <Menu.Item
                as={Link}
                to="/"
                style={{ border: 0 }}
                className="borderless no-hover"
              >
                <Image
                  src={logo}
                  alt="React Loop - The First Chicago ReactJS Conference"
                  size="small"
                />
              </Menu.Item>
              <Menu.Item position="right" onClick={handleToggle}>
                <Icon name="sidebar" />
              </Menu.Item>
            </Menu>
          </Container>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    </div>
  );
};

MobileContainer.propTypes = {
  children: PropTypes.node,
  config: PropTypes.object,
};

export default MobileContainer;
