import React from "react";
import { Container, Header, Card, Image, Icon } from "semantic-ui-react";
import PageHeader from "../components/pageheader/PageHeader";
import Layout from "../components/layout/Layout";
import useTeam from "../data-hooks/useTeam";
import shuffle from "../utils/shuffle";

const About = () => {
  const team = useTeam();
  return (
    <Layout page="about">
      <PageHeader
        className="about"
        headline="React Loop"
        subline="For developers, by developers."
      />
      <Container style={{ paddingBottom: 100 }}>
        <Header as="h2">What is React Loop?</Header>
        <p>
          React Loop is a one day single track conference for developers using
          React, React Native, and the related tools and libraries in the
          ever-growing React ecosystem.
        </p>
        <Header as="h2">What kind of topics are covered?</Header>
        <p>
          We include content for seasoned React users as well as those new to
          the community. We love the cutting edge, but we also appreciate solid
          software fundamentals.&nbsp;&nbsp;You can expect to hear about the
          latest features of React, React being used in new and interesting
          environments, techniques for building and managing React projects,
          optimizations, testing strategies, helpful libraries, and tales of
          learning and inspiration.
        </p>
        <Header as="h2">What is the schedule like?</Header>
        <p>
          We believe free time to network and to share what you're working on
          and learning with other developers is an important part of a great
          conference experience so we plan plenty of break time.&nbsp;&nbsp;We
          don't do long talks.&nbsp;&nbsp;30 minutes is the longest we allow.
        </p>
        <Header as="h2">Organizers</Header>
        <Card.Group>
          {shuffle(team).map((member, i) => (
            <Card key={i}>
              <Image alt={member.name} src={member.image} size="medium" />
              <Card.Content>
                <Card.Header>{member.name}</Card.Header>
                <Card.Meta>
                  <span
                    className="date"
                    dangerouslySetInnerHTML={{ __html: member.title }}
                  />
                </Card.Meta>
                {member.description && (
                  <Card.Description>
                    <small style={{ lineHeight: 0.5 }}>
                      {member.description}
                    </small>
                  </Card.Description>
                )}
              </Card.Content>
              {(member.github || member.twitter) && (
                <Card.Content extra textAlign="center">
                  {member.github && (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`https://www.github.com/${member.github}`}
                    >
                      <Icon size="large" name="github" />
                    </a>
                  )}
                  {member.twitter && (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`https://www.twitter.com/${member.twitter}`}
                    >
                      <Icon size="large" name="twitter" />
                    </a>
                  )}
                </Card.Content>
              )}
            </Card>
          ))}
        </Card.Group>
      </Container>
    </Layout>
  );
};

export default About;
