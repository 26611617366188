import React from "react";
import { Container, Header, List, Divider } from "semantic-ui-react";
import PageHeader from "../components/pageheader/PageHeader";
import Layout from "../components/layout/Layout";

const About = () => {
  return (
    <Layout page="convince">
      <PageHeader
        className="convince"
        headline="Dear Boss,"
        subline="Send me to React Loop."
      />
      <Container style={{ paddingBottom: 100 }}>
        <Header as="h1">
          Convince your boss to send you to React Loop 2020
        </Header>
        <p>
          Our industry requires contant learning, training, and exposure to the
          brightest minds in our fields. Attending React Loop is a huge win with
          regard to all of these requirements and it's probably easy for you to
          see that potential. However, sometimes our employers need a little
          nudge in the right direction to see the the opportunity and how they
          too can benefit by sending you to React Loop 2020
        </p>
        <Header as="h2">
          Some tips to help convince your boss to send you to React Loop
        </Header>
        <List size="big" ordered>
          <List.Item>
            Company Guidlines — There's gold in them there documents!
          </List.Item>
          <List.Item>ROI — This is training!</List.Item>
          <List.Item>Invite your boss</List.Item>
          <List.Item>Make sure everyone benefits</List.Item>
          <List.Item>Write the perfect email</List.Item>
        </List>
        <Divider />
        <Header as="h3">
          Company Guidlines — There's gold in them there documents!
        </Header>
        <p>
          Somewhere in the stack of onboarding documents you perused when you
          first joined your organization there may already be a policy about
          funding educational efforts. Be sure to look through your
          organization's policies so that you can make an informed request to
          attend React Loop.
        </p>
        <Divider />

        <Header as="h3">ROI — This is training!</Header>
        <p>
          Attending React Loop will expose you to a dozen topics presented by
          expert speakers. Show your boss the presenters and talk titles and
          explain that the amount of time it would take to learn these topics on
          your own would be much longer.
        </p>
        <p>
          The conference will also allow networking opportunities unlike any
          other medium for meeting new people in your field. Widening your
          network means having more resources to learn, ask questions, and gain
          clarity. It's invaluable!
        </p>
        <Divider />
        <Header as="h3">Invite your boss</Header>
        <p>
          Invite your boss to come and learn the latest in React as well.
          Conferences make great team-building experiences.
        </p>
        <Divider />
        <Header as="h3">Make sure everyone benefits</Header>
        <p>
          Promise to share what you learn with the team. Commit to coming back
          and presenting the five or ten most important things that you learned
          by attending. If you can present the topics that apply to your
          organization the most, this will help make your attendance a clear win
          for your entire team.
        </p>
        <Divider />
        <Header as="h3">Write the perfect email</Header>
        <p>Here's what an effective email might look like.</p>
        <div
          style={{
            borderLeft: "4px solid #ccc",
            paddingLeft: 15,
            fontStyle: "italic",
            color: "#444",
          }}
        >
          <p>Hi &lt;Boss></p>
          <p>
            Our team is working through some very tricky state management issues
            as of late and I believe we can solve these problems more
            efficiently, and for less money, with some additional training.
          </p>
          <p>
            I found a conference that focuses on the exact issues that we are
            dealing with. It's called React Loop 2020 and it is packed with
            presentations on the latest techniques in React.
          </p>
          <p>
            <List size="small">
              <List.Item>Date: June 19th 2020</List.Item>
              <List.Item>Location: Chicago, IL</List.Item>
              <List.Item>
                Cost: $229 (ticket) + $400 (airfare) + $200 (hotel) = $829
              </List.Item>
            </List>
          </p>
          <p>
            I would travel out on Thursday June 18th after work and be back for
            work the following Monday.
          </p>
          <p>
            I plan on presenting to our team all of the information I gather at
            the conference.
          </p>
          <p>-- Me</p>
        </div>
        <Divider />
        <Header as="h3">Good luck!</Header>
        <p>
          There a number of ways of getting your boss onboard with sending you
          to React Loop 2020 and we hope these tips help get the job done. See
          you in June!
        </p>
      </Container>
    </Layout>
  );
};

export default About;
