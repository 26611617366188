import React from "react";
import PageHeader from "../components/pageheader/PageHeader";
import { Container } from "semantic-ui-react";
import Layout from "../components/layout/Layout";
import ScheduleFull from "../components/schedule/ScheduleFull";
import ScheduleSummary from "../components/schedule/ScheduleSummary";
import ScheduleTalks from "../components/schedule/ScheduleTalks";
import useSchedule from "../data-hooks/useSchedule";
import useConfig from "../data-hooks/useConfig";
import useSpeakers from "../data-hooks/useSpeakers";
import useLinks from "../data-hooks/useLinks";

const Schedule = () => {
  const schedule = useSchedule();
  const config = useConfig();
  const speakers = useSpeakers();
  const links = useLinks();
  const videoCta = config.videos
    ? { localLink: true, cta: "Check out the videos", href: "/videos" }
    : {
        cta: "Call for Presenters",
        href: config.cfp ? links.PROPOSAL_FORM : null,
      };

  const tyler = speakers.find(s => s.twitter === "tylermcginnis");
  const kent = speakers.find(s => s.twitter === "kentcdodds");
  return (
    <Layout page="schedule">
      <PageHeader
        className="schedule"
        headline="The Schedule"
        subline="What, when, where, and who."
        localLink
        {...videoCta}
      />
      <Container>
        {config.scheduleSummaryOnly ? (
          <ScheduleSummary />
        ) : config.scheduleTalksOnly ? (
          <ScheduleTalks tyler={tyler} kent={kent} schedule={schedule} speakers={speakers} />
        ) : (
          <ScheduleFull schedule={schedule} />
        )}
      </Container>
    </Layout>
  );
};

export default Schedule;
