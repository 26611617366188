import { useStaticQuery, graphql } from "gatsby";

const useTickets = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allAirtable(
          filter: { table: { eq: "tickets" } }
          sort: { fields: data___date }
        ) {
          edges {
            node {
              data {
                name
                description
                available
                date
                price
                display
                soldout
              }
            }
          }
        }
      }
    `
  );

  return data.allAirtable.edges.map(({ node }) => node.data);
};

export default useTickets;
