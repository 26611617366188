import { useStaticQuery, graphql } from "gatsby";

const useLinks = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allAirtable(filter: { table: { eq: "links" } }) {
          edges {
            node {
              data {
                name
                url
              }
            }
          }
        }
      }
    `
  );

  return data.allAirtable.edges.reduce(
    (accum, cur) => ({ ...accum, [cur.node.data.name]: cur.node.data.url }),
    {}
  );
};

export default useLinks;
