import React from "react";
import { Container, Header } from "semantic-ui-react";
import { Link } from "gatsby";
import "./Diversity.css";

const Diversity = () => (
  <div className="scholarship-container">
    <Container style={{ padding: "50px 0" }}>
      <Header as="h2" content="Scholarship Tickets" />
      <p>
        React Loop is offering a set of tickets as a part of the React Loop 2020
        Scholarship Program to members of underrepresented groups in tech at no
        cost.  The application process is now closed.
      </p>
    </Container>
  </div>
);

export default Diversity;
