import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Item, Icon, Container } from "semantic-ui-react";
import "./ImportantDates.css";

const ImportantDates = ({ dates }) => {
  return (
    <div className="important-dates-container">
      <Container style={{ marginTop: 45 }}>
        <Item.Group className="important-dates">
          <Item className="important-date">
            <Icon name="calendar alternate outline" size="huge" color="grey" />
            <Item.Content style={{ marginTop: 10 }}>
              <Item.Header>December 01, 2019</Item.Header>
              <Item.Description>Call for Proposals opens</Item.Description>
            </Item.Content>
          </Item>
          <Item className="important-date">
            <Icon name="calendar alternate outline" size="huge" color="grey" />
            <Item.Content style={{ marginTop: 10 }}>
              <Item.Header>February 03, 2020</Item.Header>
              <Item.Description>Call for Proposals closes</Item.Description>
            </Item.Content>
          </Item>
          <Item className="important-date">
            <Icon name="calendar alternate outline" size="huge" color="grey" />
            <Item.Content style={{ marginTop: 10 }}>
              <Item.Header>February 10, 2020</Item.Header>
              <Item.Meta>Tickets Available</Item.Meta>
            </Item.Content>
          </Item>
        </Item.Group>
      </Container>
    </div>
  );
};

ImportantDates.propTypes = {
  dates: PropTypes.object,
};

export default ImportantDates;
