import { useStaticQuery, graphql } from "gatsby";

const useSchedule = () => {
  const data = useStaticQuery(
    graphql`
      {
        allAirtable(
          filter: { table: { eq: "schedule" } }
          sort: { fields: data___time }
        ) {
          edges {
            node {
              data {
                time
                title
                description
                type
                speaker {
                  data {
                    name
                    talk_title
                    talk_description
                    yt_video_id
                    image {
                      localFiles {
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return data.allAirtable.edges.map(({ node }) => ({ ...node.data }));
};

export default useSchedule;
