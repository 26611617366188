import React from "react";
import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";
const FooterCFP = ({ links }) => (
  <>
    <Header inverted as="h4" content="Call for Presenters" />
    <Button
      style={{ letterSpacing: "0.03em" }}
      rel="noopener noreferrer"
      as="a"
      href={links.PROPOSAL_FORM}
      target="_blank"
      primary
      size="tiny"
    >
      Submit a Presentation
    </Button>
  </>
);

FooterCFP.propTypes = {
  links: PropTypes.object,
};

export default FooterCFP;
