import React from "react";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/pageheader/PageHeader";
import useConfig from "../data-hooks/useConfig";
import useLinks from "../data-hooks/useLinks";
import useSpeakers from "../data-hooks/useSpeakers";
import useTickets from "../data-hooks/useTickets";
import useDates from "../data-hooks/useDates";
import Diversity from "../components/home/Diversity";
import TicketGroups from "../components/home/TicketGroups";
import ImportantDates from "../components/home/ImportantDates";
import Hotels from "../components/home/Hotels";
import Workshop from "../components/home/Workshop";
import FeaturedSpeakers from "../components/home/FeaturedSpeakers";
import Venue from "../components/home/Venue";
import Intro from "../components/home/Intro";
import Convince from "../components/home/Convince";

const Home = () => {
  const config = useConfig();
  const links = useLinks();
  const speakers = useSpeakers();
  const tickets = useTickets();
  const dates = useDates();
  return (
    <Layout page="home">
      <PageHeader
        className="home"
        headline="React Loop"
        cta="Call for Presenters"
        href={config.cfp ? links.PROPOSAL_FORM : null}
        subline="June 19, 2020 @ Venue SIX10"
      />
      <Intro />
      {config.featuredSpeakers && (
        <>
          <FeaturedSpeakers config={config} speakers={speakers} />
        </>
      )}
      <Convince />
      {config.importantDates && (
        <>
          <ImportantDates dates={dates} />
        </>
      )}
      {/* {config.ticketInfo && (
        <>
          <TicketGroups link={links.TICKET_SALES} tickets={tickets} />
        </>
      )} */}
      {config.workshop && (
        <>
          <Workshop link={links.TICKET_SALES} />
        </>
      )}
      
        <>
          <Diversity />
        </>
      
      <Hotels />
      <Venue />
    </Layout>
  );
};

export default Home;
