import { useStaticQuery, graphql } from "gatsby";
import shuffle from "../utils/shuffle";
const useSpeakers = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allAirtable(filter: { table: { eq: "speakers" } }) {
          edges {
            node {
              data {
                name
                github
                twitter
                title
                talk_title
                talk_description
                featured
                active
                image {
                  localFiles {
                    publicURL
                  }
                }
                schedule {
                  data {
                    time
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const dat = data.allAirtable.edges
    .filter(({ node }) => node.data.name)
    .map(({ node }) => ({
      ...node.data,
      image: node.data.image.localFiles[0].publicURL,
    }));
  return dat;
};

export default useSpeakers;
