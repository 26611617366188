import React from "react";
import PropTypes from "prop-types";
import DesktopContainer from "./DesktopContainer";
import MobileContainer from "./MobileContainer";
import Footer from '../footer/Footer';
import SEO from "../seo";
import useConfig from "../../data-hooks/useConfig";
import useLinks from "../../data-hooks/useLinks";

const Layout = ({ page, children }) => {
  const config = useConfig();
  const links = useLinks();
  config.links = links;

  return (
    <>
      <SEO page={page} />
      <DesktopContainer config={config}>{children}</DesktopContainer>
      <MobileContainer config={config}>{children}</MobileContainer>
      <Footer config={config} />
    </>
  );
};

Layout.propTypes = {
  page: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;
