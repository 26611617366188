import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Header, Button, Message, Icon } from "semantic-ui-react";
import MailListForm from "../layout/MailListForm";
import useConfig from "../../data-hooks/useConfig";
import useLinks from "../../data-hooks/useLinks";
import { Spring, config as springConfig } from "react-spring/renderprops";
import Coronavirus from "./Coronavirus";
import "./PageHeader.css";

const rand = (n, min = 1) => Math.floor(Math.random() * (n - min + 1)) + min;

const PageHeader = ({ className, headline, subline, cta, href, localLink }) => {
  const config = useConfig();
  const links = useLinks();
  const hx = rand(1000, 200) * -1;
  const sx = rand(1000, 200) * -1;
  const immediate =
    typeof window === "object" ? document.body.clientWidth < 768 : false;

  return (
    <>
      <div className={`loop-header ${className}`}>
        <div className="loop-header-container">
          <Spring
            immediate={immediate}
            config={springConfig.wobbly}
            from={{ x: hx }}
            to={{ x: 0 }}
          >
            {({ x }) => (
              <div className="block-header" style={{ marginLeft: x }}>
                <Header as="h1" content={headline} />
              </div>
            )}
          </Spring>
          {/* {subline && ( */}
            <Spring
              immediate={immediate}
              config={springConfig.gentle}
              from={{ x: sx }}
              to={{ x: 0 }}
            >
              {({ x }) => (
                <div className="block-header" style={{ marginLeft: x, backgroundColor: 'red' }}>
                  <Header
                    color="white"
                    as="h2"
                    content="this event has been canceled"
                  />
                </div>
              )}
            </Spring>
            <Button
              style={{ fontWeight: 300, marginBottom: 10 }}
              as={Link}
              to="/covid-19"
              size="huge"
            >
              Learn more
            </Button>
          {/* )} */}
          {/* {config.tickets && !config.soldOut && (
            <Button
              style={{ fontWeight: 300, marginBottom: 10 }}
              as="a"
              positive
              href={links.TICKET_SALES}
              target="_blank"
              size="huge"
            >
              Buy Your Ticket
            </Button>
          )} */}
          {href &&
            cta &&
            (localLink ? (
              <Button
                style={{ fontWeight: 300, marginBottom: 10 }}
                as={Link}
                to={href}
                primary
                size="huge"
              >
                {cta}
              </Button>
            ) : (
              <Button
                style={{ fontWeight: 300, marginBottom: 10 }}
                as="a"
                href={href}
                target="_blank"
                primary
                size="huge"
              >
                {cta}
              </Button>
            ))}
        </div>
        <div
          className="page-header-mail-form"
          style={{ backgroundColor: "#016994" }}
        >
          <div className="page-header-mail-form-label">
            Subscribe for React Loop updates and news:{" "}
          </div>
          <MailListForm />
        </div>
        {/* {config.cfp && (
        <div className="page-header-cfp">
          <Message color="blue" icon>
            <Icon name="announcement" />
            <Message.Content>
              <Message.Header>
                Have you heard that the React Loop 2020 Call for Presenters is
                now open?
              </Message.Header>
              <Button
                primary
                as="a"
                href={links.PROPOSAL_FORM}
                style={{ letterSpacing: "0.03em", marginTop: 10 }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Submit your presentation today!
              </Button>
            </Message.Content>
          </Message>
        </div>
      )} */}
      </div>
      <Coronavirus />
    </>
  );
};

PageHeader.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  subline: PropTypes.string,
  cta: PropTypes.string,
  href: PropTypes.string,
  localLink: PropTypes.bool,
};

export default PageHeader;
