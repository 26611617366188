import React from "react";
import { Header, Container } from "semantic-ui-react";

const Intro = () => (
  <Container style={{ padding: "50px 0", position: "relative", top: -18 }}>
    <Header
      as="h3"
      style={{
        lineHeight: 1.8,
        fontSize: "1.7em",
        textAlign: "center",
        fontWeight: 500,
      }}
    >
      React Loop is a single day ReactJS conference bringing together a diverse
      and inclusive group of React enthusiasts from all backgrounds and levels
      of experience. Speakers will cover topics such as React, React Native, and
      the related tools in the ever-growing React ecosystem.
    </Header>
  </Container>
);

export default Intro;
