import React from "react";
import Layout from "../components/layout/Layout";
import { Container, Divider } from "semantic-ui-react";

const COVID19 = () => (
  <Layout page="covid-19">
    <Container style={{ marginTop: 100, marginBottom: 500 }}>
      <h1>A statement concerning COVID-19 from the React Loop Team</h1>
      <p style={{ color: "grey" }}>Updated: April 24, 2020</p>
      <p>Due to the ongoing pandemic React Loop 2020 has been canceled.</p>
      <p>
        All tickets holders and sponsors are or will be refunded as soon as
        possible.
      </p>
      <p>
        We would like to thank our sponsors;{" "}
        <a href="https://formidable.com/">Formidable</a>,{" "}
        <a href="https://www.oreilly.com/">O'Reilly</a>,{" "}
        <a href="https://balsamiq.com/">Balsamiq</a>,{" "}
        <a href="https://www.glassdoor.com/index.htm">Glassdoor</a>, and{" "}
        <a href="https://www.digitalprimates.net/">Digital Primates</a>. These
        are all amazing organizations who supported our community.
      </p>
      <p>
        Thank you to everyone who bought a ticket! We're still the new kids on
        the block regarding conferences and we really appreciate you taking a
        chance on us.
      </p>
      <Divider />
      <h3>No promises, but we hope we can...</h3>
      <p>
        As a ticket holder you were automatically entered into a raffle for some
        really great prizes. If we can get our sponsors to agree we'll be
        conducting that raffle after all other concerns are handled and will let
        you know if you've won and ship the prize to you.
      </p>
      <p>
        In the coming weeks we hope to work with some of our 2020 speakers to
        produce videos of their presentations which will be shared freely with
        the community on our YouTube channel. All of the speakers were chosen
        from hundreds of others because of the unique and exciting content they
        had to share. It won't be the same as being at the conference, but we we
        hope we can help share the content they intended to deliver to you and
        at no charge.
      </p>
      <Divider />
      <p>
        Lastly, we hope we can all gather when this crisis passes and geek out
        over some amazing React presentations in Chicago in 2021.
      </p>
      <p>
        oh and... <b>fuck you COVID-19!</b>
      </p>
    </Container>
  </Layout>
);

export default COVID19;
