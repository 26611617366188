import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ lang, page = "home" }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            pages {
              page
              title
              description
            }
          }
        }
      }
    `
  );

  const thisPage = site.siteMetadata.pages.find(p => p.page === page);
  const metaDescription = thisPage.description;
  const metaTitle = thisPage.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:content`,
          content: metaTitle,
        },
        {
          property: `og:image`,
          content: `https://reactloop.com/images/og-fb.png`,
        },
        {
          property: `og:url`,
          content: `https://reactloop.com/`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@reactloop`,
        },
        {
          name: `twitter:site`,
          content: `@reactloop`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `https://reactloop.com/images/og-tw.png`,
        },
        {
          name: `twitter:image:alt`,
          content: `React Loop 2019 - A Chicago ReactJS Conference`,
        },
        {
          name: 'google-site-verification',
          content: 'vhuUn8VotckujF3YBc3g1XYQGLkBEL-DKuO2QZ6dBxg'
        }
      ]}
      link={[
        {
          rel: "icon",
          type: "image/png",
          size: "114x114",
          href: "https://reactloop.com/apple-touch-icon.png",
        },
        {
          rel: "icon",
          type: "image/png",
          size: "32x32",
          href: "https://reactloop.com/favicon-32x32.png",
        },
        {
          rel: "icon",
          type: "image/png",
          size: "16x16",
          href: "https://reactloop.com/favicon-16x16.png",
        },
        {
          rel: "mask-icon",
          color: "#5bbad5",
          href: "https://reactloop.com/safari-pinned-tab.svg",
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
};

SEO.propTypes = {
  page: PropTypes.string,
  lang: PropTypes.string,
};

export default SEO;
