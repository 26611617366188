import React from "react";
import { Container, Header, Image, Button } from "semantic-ui-react";
import { Link } from "gatsby";
import kcd from "./kcd.jpg";
import "./Workshop.css";

const Workshop = () => (
  <div className="workshop-container">
    <Container style={{ padding: "50px 0" }}>
      <div className="workshop-home">
        <div className="instructor">
          <Image src={kcd} size="small" circular />
        </div>

        <div className="workshop">
          <Header as="h2">React Suspense Workshop<br /> w/ Kent C. Dodds</Header>
          <p>
            Join Kent C. Dodds for a hands-on React Suspense workshop on June
            18th, 2020.
          </p>
          <Button primary as={Link} to="/workshop">
            Learn more
          </Button>
        </div>
      </div>
    </Container>
  </div>
);

export default Workshop;
