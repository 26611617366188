import React from "react";
import Speaker from "../components/speaker/Speaker";
import PageHeader from "../components/pageheader/PageHeader";
import { Container, Card } from "semantic-ui-react";
import Layout from "../components/layout/Layout";
import useSpeakers from "../data-hooks/useSpeakers";
import useConfig from "../data-hooks/useConfig";
import useLinks from "../data-hooks/useLinks";

const Speakers = () => {
  const speakers = useSpeakers();
  const config = useConfig();
  const links = useLinks();
  return (
    <Layout page="speakers">
      <PageHeader
        className="speakers"
        headline="The Speakers"
        subline="a.k.a The Greatest Speaker List Ever. 🔥"
        cta="Call for Presenters"
        href={config.cfp ? links.PROPOSAL_FORM : null}
      />
      <Container className="speakers-cards">
        {config.speakers ? (
          <Card.Group>
            {speakers.map(speaker => (
              <Speaker key={speaker.name} speaker={speaker} />
            ))}
          </Card.Group>
        ) : (
          <div style={{ marginBottom: 500 }}>
            <p>
              We will post the full speaker list once all proposals have been
              submitted and reviewed. Stay tuned!.
            </p>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default Speakers;
