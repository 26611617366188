import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { List, Header } from "semantic-ui-react";

const FooterSitemap = ({ config }) => {
  const {
    speakers,
    sponsors,
    schedule,
    venue,
    about,
    conduct,
    diversityTickets,
  } = config;
  const items = { speakers, schedule, venue, sponsors, about, conduct };
  return (
    <>
      <Header inverted as="h4" content="Sitemap" />
      <List link inverted>
        <List.Item as={Link} to="/">
          Home
        </List.Item>
        {Object.keys(items).map((key, index) => {
          if (items[key]) {
            return (
              <List.Item
                as={Link}
                to={`/${key}`}
                key={key}
                className="footer-link"
              >
                {key}
              </List.Item>
            );
          }
          return null;
        })}
        <List.Item as={Link} to="/conduct">
          Conduct
        </List.Item>
        {diversityTickets && (
          <List.Item as={Link} to="/scholarship">
            Scholarship
          </List.Item>
        )}
      </List>
    </>
  );
};

FooterSitemap.propTypes = {
  config: PropTypes.object,
};

export default FooterSitemap;
