import React from "react";
import Layout from "../components/layout/Layout";

import { Container } from "semantic-ui-react";

const NotFoundPage = () => (
  <Layout page="404">
    <Container style={{ marginTop: 250, marginBottom: 800 }}>
      <h1>Dang.</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
);

export default NotFoundPage;
