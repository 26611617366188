import React from "react";
import { Container, Header } from "semantic-ui-react";
import { Link } from "gatsby";
import "./Coronavirus.css";

const Coronavirus = () => (
  <div className="coronavirus-container" style={{ marginTop: -55 }}>
    <Container>
      <p style={{ textAlign: "center" }}>
        <Link to="/covid-19">React Loop 2020 has been canceled.  Please read our COVID-19 update page</Link>
      </p>
    </Container>
  </div>
);

export default Coronavirus;
