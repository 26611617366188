import React from "react";
import { Container, Header } from "semantic-ui-react";
import { Link } from "gatsby";
import "./Convince.css";

const Convince = () => (
  <div className="convince-container">
    <Container style={{ padding: "50px 0" }}>
      <Header as="h2" content="Convince your boss" />
      <p>
        Sometimes our employers need a little nudge in the right direction to
        see the the opportunity and how they too can benefit by sending you to
        React Loop 2020. <Link to="/convince">Here are some helpful tips.</Link>
      </p>
    </Container>
  </div>
);

export default Convince;
