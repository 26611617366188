import { useStaticQuery, graphql } from "gatsby";

const useSponsors = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allAirtable(
          filter: { table: { eq: "sponsorship" } }
          sort: { fields: data___order }
        ) {
          edges {
            node {
              data {
                name
                sponsors {
                  data {
                    name
                    url
                    active
                    image {
                      localFiles {
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return data.allAirtable.edges.map(({ node }) => ({
    ...node.data,
    sponsors: node.data?.sponsors?.map(s => ({
      ...s.data,
      image: s.data.image.localFiles[0].publicURL,
    })),
  }));
};

export default useSponsors;
