import React from "react";
import Layout from "../components/layout/Layout";
import {
  Container,
  List,
  Image,
  Divider,
  Card,
  Button,
  Segment,
} from "semantic-ui-react";
import PageHeader from "../components/pageheader/PageHeader";
import kcd from "../components/home/kcd.jpg";
import useConfig from "../data-hooks/useConfig";
import useLinks from "../data-hooks/useLinks";

const Conduct = () => {
  const { tickets } = useConfig();
  const links = useLinks();
  return (
    <Layout page="workshop">
      <PageHeader
        className="workshop"
        headline="Level up your React skills"
        subline="with Kent C. Dodds - June 18, 2020"
      />

      <Container>
        <h1>React Loop 2020 - React Suspense Workshop</h1>
        <p>
          Join Kent C. Dodds for a hands-on React Suspense workshop on June 18,
          2020
        </p>
        <p>Hosted by Glassdoor - 1330 W Fulton St floor 6, Chicago, IL 60607</p>
        <Divider />
        <p>
          How we develop React applications has change significantly over the
          last couple of years. Hooks changed how we deal with lifecycle events
          and state. The Context API changed how we share data among components.
        </p>
        <p>
          <em>Another game changer is upon us and it's Suspense.</em>
        </p>
        <p>
          Hack away at it and watch some videos and you'll get there eventually,
          but if you want to master Suspense now, do yourself a favor and let
          Kent C. Dodds show you the way in this hands-on React Loop 2020
          workshop the day before React Loop on June 18, 2020.
        </p>
        <Divider />
        <h2>About the Instructor: Kent C. Dodds</h2>
        <div className="flex flex-column">
          <div className="mb3">
            <Image src={kcd} size="small" circular />
          </div>
          <div>
            <p>
              Kent C. Dodds is a world renowned speaker, teacher, and trainer
              and he's actively involved in the open source community as a
              maintainer and contributor of hundreds of popular npm packages.
              Kent is the creator of TestingJavaScript.com and he's an
              instructor on egghead.io and Frontend Masters. He's also a Google
              Developer Expert. Kent is happily married and the father of four
              kids. He likes his family, code, JavaScript, and React.
            </p>
          </div>
        </div>
        <Divider />

        <h2>About the workshop</h2>
        <h3>Simplify your Async UI and improve your User Experience</h3>
        <p>
          One of the biggest challenges to writing software for the web is
          dealing with asynchrony -- even though we have to deal with it all the
          time. This leads to a bunch of boilerplate code for handling loading
          and error states just to get the data we need to show the user--
          whether the data comes from a remote endpoint or from browser APIs
          like geolocation and Bluetooth. The end result is countless spinners
          and the dreaded FOLC (flash of loading content).
        </p>

        <p>
          React Suspense is the answer to these problems. It's a primitive built
          into React that drastically simplifies asynchronous state management
          in our applications, and helps you avoid FOLC out of the box. It's a
          bit of a different approach to managing these problems and
          understanding how it works is key to taking advantage of what it has
          to offer.
        </p>

        <p>
          In this workshop, you'll learn how Suspense works under the hood,
          preparing you for the future of asynchronous state management.
        </p>

        <p>At the end of the workshop, you will:</p>
        <List size="huge">
          <List.Item
            icon="check circle green"
            content="Know how Suspense works fundamentally"
          />
          <List.Item
            icon="check circle green"
            content="Be able to write your own Suspense-based abstraction"
          />
          <List.Item
            icon="check circle green"
            content="Understand the benefits and how to of 'render as you fetch'"
          />
          <List.Item
            icon="check circle green"
            content="Know how to use useTransition and useDeferredValue effectively"
          />
          <List.Item
            icon="check circle green"
            content="Know how to write your own cache for suspense-based abstractions"
          />
          <List.Item
            icon="check circle green"
            content="Know how to use to coordinate multiple suspending components"
          />
        </List>
        <Divider />
        <h2>Prerequisites</h2>
        <p>
          Watch{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.youtube.com/watch?v=nLF0n9SACd4"
          >
            Dan Abramov’s talk Beyond React 16 | JSConf Iceland 2018
          </a>{" "}
          (33 minutes)
        </p>
        <p>
          Go through Kent’s{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://kentcdodds.com/workshops/hooks"
          >
            Learn React Hooks Workshop
          </a>
          , or have the equivalent basic experience of using hooks. You should
          be experienced with useState, useEffect, and useRef.
        </p>
        <p>
          Go through Kent’s{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://kentcdodds.com/workshops/advanced-react-hooks"
          >
            Advanced React Hooks Workshop
          </a>
          , or have the equivalent experience. You should be experienced with
          useContext and useReducer (experience with useMemo and useCallback is
          a bonus).
        </p>
        <div className="mv4">
          {/* <Card.Group>
            <Card>
              <Card.Content>
                <Card.Header>Early Bird Workshop</Card.Header>
                <Card.Meta>$399</Card.Meta>
                <Card.Description style={{ height: 40 }}>
                  Limited amount of Early bird tickets for the workshop June 18,
                  2020
                </Card.Description>
              </Card.Content>
              <Card.Content>
                {tickets ? (
                  <Button
                    color={"green"}
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={links.TICKET_SALES}
                    size="large"
                  >
                    Buy Now
                  </Button>
                ) : (
                  <span>Available: February 10, 2020</span>
                )}
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Regular Bird Workshop</Card.Header>
                <Card.Meta>$499</Card.Meta>
                <Card.Description style={{ height: 40 }}>
                  One ticket to the workshop on June 18, 2020
                </Card.Description>
              </Card.Content>
              <Card.Content>
                {tickets ? (
                  <Button
                    color={"green"}
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={links.TICKET_SALES}
                    size="large"
                  >
                    Buy Now
                  </Button>
                ) : (
                  <span>Available: February 10, 2020</span>
                )}
              </Card.Content>
            </Card>
          </Card.Group> */}
          <Divider />
          
          <p style={{fontSize: 16}}>Hosted by Glassdoor - Worskhop takes place at 1330 W Fulton St floor 6, Chicago, IL 60607.  Begins at 9:30am - 5pm.  Lunch is provided.  Full details will be provided via email before the workshop.</p>
          
        </div>
      </Container>
    </Layout>
  );
};

export default Conduct;
