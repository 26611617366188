import React from "react";
import { Header, List } from "semantic-ui-react";

const FooterSocial = () => {
  return (
    <>
      <Header inverted as="h4" content="Social" />
      <List link inverted>
        <List.Item
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UCBoSWHAAhkqrCBsTAeS5cCA"
        >
          YouTube
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href="http://twitter.com/reactloop"
        >
          Twitter
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/react-loop"
        >
          LinkedIn
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/reactloop/"
        >
          Instagram
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/reactloop"
        >
          Facebook
        </List.Item>
      </List>
    </>
  );
};

export default FooterSocial;
