import React from "react";
import Layout from "../components/layout/Layout";
import { Link } from "gatsby";
import { Container, Image, Divider, Statistic } from "semantic-ui-react";
import PageHeader from "../components/pageheader/PageHeader";
import shuffle from "../utils/shuffle";
import useConfig from "../data-hooks/useConfig";
import useSponsors from "../data-hooks/useSponsors";
import "./sponsors.css";

const logoSizes = {
  Premier: 500,
  Platinum: 450,
  Gold: 350,
  Silver: 300,
  Partner: 200,
};

const old = [
  { url: "http://rbi.com", image: "rbi.png" },
  {
    url: "http://ftdcompanies.com/jobopportunities",
    image: "ftd.jpg",
    active: true,
  },
  {
    url: "https://digitalprimates.net",
    image: "digitalprimates.png",
    active: true,
  },
  { url: "https://www.teksystems.com", image: "teksystems.png" },
  { url: "https://pluralsight.com", image: "pluralsight.png" },
  { url: "https://progress.com", image: "progress.png" },
  { url: "https://medium.com", image: "medium.png" },
  { url: "https://simplygoodwork.com", image: "goodwork.png" },
  { url: "https://digitalmint.io", image: "digitalmint.png" },
  { url: "https://www.oreilly.com/", image: "oreilly.png" },
  { url: "https://codepen.io/", image: "codepen.png" },
];

const Sponsors = () => {
  const config = useConfig();
  const sponsorLevels = useSponsors();
  const sponsorCount = sponsorLevels.map(sl => sl.sponsors).flat(Infinity).filter(s => s.active)
    .length;
  return (
    <Layout page="sponsors">
      <PageHeader
        className="sponsors"
        headline="We ❤️ our sponsors. They're pretty great."
        subline="Get your organization involved."
      />
      <Container>
        {config.takingSponsors && (
          <>
            <Statistic.Group>
              <Statistic>
                <Statistic.Value>1</Statistic.Value>
                <Statistic.Label>Day</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>350</Statistic.Value>
                <Statistic.Label>Attendees</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value text>
                  TWENTY
                  <br />
                  THOUSAND
                </Statistic.Value>
                <Statistic.Label>Youtube views</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>12</Statistic.Value>
                <Statistic.Label>Speakers</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value text>
                  Unlimited
                  <br />
                  Potential for
                </Statistic.Value>
                <Statistic.Label>Networking</Statistic.Label>
              </Statistic>
            </Statistic.Group>
            <Divider />

            <div style={{ marginTop: 50, marginBottom: 50 }}>
              <p
                style={{ textAlign: "center", maxWidth: 768, margin: "0 auto" }}
              >
                If you're interested in how your organization can support React
                Loop 2020 please review our{" "}
                <a
                  href="/ReactLoop-Sponsorship-2020.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Sponsor Prospectus
                </a>{" "}
                and reach out to us at{" "}
                <a href="mailto:sponsor@reactloop.com">sponsor@reactloop.com</a>
                .
              </p>
            </div>
          </>
        )}
        {!config.sponsorsPitchOnly ? (
          <>
           <Divider
              as="h4"
              className="header"
              horizontal
              style={{ margin: "3em 0em", textTransform: "uppercase" }}
            >
              2020 Sponsors
            </Divider>
            {sponsorLevels
              .filter(l => l.sponsors && l.sponsors.some(s => s.active))
              .map((level, levelIndex) => {
                return (
                  <div key={level.name}>
                    <Container className="sponsor-level-container">
                      {shuffle(level.sponsors)
                        .filter(s => s.active)
                        .map(({ url, name, image }, sponsorIndex) => (
                          <a
                            href={url}
                            rel="noopener noreferrer"
                            target="_blank"
                            key={`${levelIndex}-${sponsorIndex}`}
                          >
                            <div className="sponsor-link">
                              <Image
                                alt={name}
                                style={{
                                  width: logoSizes[level.name],
                                  height: logoSizes[level.name],
                                }}
                                centered
                                src={image}
                              />
                            </div>
                          </a>
                        ))}
                    </Container>
                  </div>
                );
              })}
          </>
        ) : (
          <div>
            <p>
              We are excited to have your organization involved with React Loop.
              React Loop is a one day community conference focused on topics
              related to the React.js framework. Sponsoring React Loop is a
              great way to reach skilled and passionate developers who are
              building front-end applications with React, iOS and Android
              projects with React Native, GraphQL, Universal Javascript on the
              server, and much more.
            </p>
            <p>
              Please reach out to us at{" "}
              <a href="mailto:sponsor@reactloop.com">sponsor@reactloop.com</a>{" "}
              to see how your organization can be involved in the conference.
            </p>
            <p>
              React Loop has a <Link to="/conduct">code of conduct</Link> to
              which all participants and sponsors are held accountable.
            </p>
          </div>
        )}
        {sponsorCount < 4 && (
          <>
            <Divider
              as="h4"
              className="header"
              horizontal
              style={{ margin: "3em 0em", textTransform: "uppercase" }}
            >
              2019 Sponsors
            </Divider>
            <div className="ui container sponsor-level-container">
              {old.map((sponsor, index) => (
                <a
                  key={index}
                  href={sponsor.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="sponsor-link">
                    <img
                      alt="sponsor"
                      src={`https://2019.reactloop.com/assets/sponsor_logos/${sponsor.image}`}
                      className="ui centered image"
                      style={{ width: 230, height: 230 }}
                    />
                  </div>
                </a>
              ))}
            </div>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default Sponsors;
