import { useStaticQuery, graphql } from "gatsby";

const useTeam = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allAirtable(filter: { table: { eq: "team" } }) {
          edges {
            node {
              data {
                name
                github
                twitter
                title
                description
                image {
                  localFiles {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return data.allAirtable.edges.map(({ node }) => ({
    ...node.data,
    image: node.data.image.localFiles[0].publicURL,
  }));
};

export default useTeam;
