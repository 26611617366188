import React from "react";
import { Container } from "semantic-ui-react";
import { Link } from "gatsby";

const Venue = () => (
  <div style={{borderTop: '1px solid #ccc'}}>
    <Container style={{ padding: "50px 0 0 0" }} className="home-venue">
      <p style={{textAlign: 'center'}}>
        React Loop 2020 will take place at Venue SIX10 located at 610 S.
        Michigan Ave, Chicago IL. <Link to="/venue">Learn more</Link>
      </p>
    </Container>
    </div>
);

export default Venue;
