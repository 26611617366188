import React from "react";
import { Container, Header, Card } from "semantic-ui-react";

const hotels = [
  {
    name: "The Blackstone",
    address: "636 S Michigan Avenue, Chicago, IL 60605",
    distance: "246ft from the venue",
    url: "https://www.theblackstonehotel.com/",
  },
  {
    name: "Congress Plaza",
    address: "520 S Michigan Ave, Chicago, IL 60605",
    distance: "500ft from the venue",
    url: "https://www.congressplazahotel.com/",
  },
  {
    name: "Hilton Chicago",
    address: "720 S Michigan Ave, Chicago, IL 60605",
    distance: "1 block from the venue",
    note: "Speaker Hotel",
    url:
      "https://www3.hilton.com/en/hotels/illinois/hilton-chicago-CHICHHH/index.html",
  },
  {
    name: "Travelodge by Wyndham",
    address: "65 E Harrison St, Chicago, IL 60605",
    distance: "433ft from the venue",
    url:
      "https://www.wyndhamhotels.com/travelodge/chicago-illinois/travelodge-hotel-downtown-chicago/overview",
  },
  {
    name: "Hotel Essex",
    address: "800 S Michigan Ave, Chicago, IL 60605",
    distance: "2 blocks from the venue",
    url: "https://www.hotelessexchicago.com/",
  },
  {
    name: "Best Western",
    address: "1100 S Michigan Ave, Chicago, IL 60605",
    distance: "5 blocks from the venue",
    url: "https://www.bwgrantparkhotel.com/",
  },
];

const Hotels = () => {
  return (
     <Container style={{ padding: "50px 0" }} className="home-hotels">
        <p>
          There are no official conference accommodations, but there are several
          hotels nearby, including:
        </p>
        <Card.Group className="hotel-cards">
          {hotels.map((h, i) => (
            <Card
              key={i}
              as="a"
              href={h.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Card.Content>
                <Card.Header>
                  {h.name} {h.note && <small>({h.note})</small>}
                </Card.Header>
                <Card.Meta>{h.address}</Card.Meta>
                <Card.Description>{h.distance}</Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        <br />
        <p style={{ fontSize: 14 }}>
          There are plenty more within walking distance of the venue.
        </p>
      </Container>
  );
};

export default Hotels;
