import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import {
  Segment,
  Container,
  Header,
  Grid,
  Divider,
  List,
} from "semantic-ui-react";
import FooterSocial from "./FooterSocial";
import FooterSitemap from "./FooterSitemap";
import FooterCFP from "./FooterCFP";
import FooterTickets from "./FooterTickets";
import MailListForm from "../layout/MailListForm";
const Footer = ({ config }) => {
  const { cfp, tickets, soldOut, links } = config;
  const showCTAColumn = true;
  return (
    <Segment inverted vertical style={{ marginTop: 80, padding: "3em 0em" }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={2}>
              <FooterSitemap config={config} />
            </Grid.Column>
            <Grid.Column width={2}>
              <FooterSocial />
            </Grid.Column>
            {showCTAColumn && (
              <Grid.Column width={4}>
                <div>
                  {/* {tickets && !soldOut && <FooterTickets links={links} />} */}
                  <div style={{margin: '15px 0'}}>
                    <Link style={{ color: "red" }} to="/covid-19">
                      Coronavirus Update
                    </Link>
                  </div>

                  {cfp && <FooterCFP links={links} />}
                  <List link inverted>
                    <Header inverted as="h4" content="Previous" />
                    <List.Item
                      as="a"
                      href="https://2019.reactloop.com"
                      target="_blank"
                    >
                      React Loop 2019
                    </List.Item>
                  </List>
                </div>
              </Grid.Column>
            )}

            <Grid.Column width={showCTAColumn ? 6 : 12}>
              <Header as="h4" inverted>
                React Loop - June 19, 2020 @ Venue SIX10
              </Header>
              <div id="mc_embed_signup">
                <p>Subscribe for Updates</p>
                <MailListForm />
              </div>
              <div style={{ marginTop: 25 }}>
                <a style={{ color: "#fff" }} href="mailto:info@reactloop.com">
                  info@reactloop.com
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <div style={{ width: "100%", marginTop: 50 }}>
        <Divider
          as="a"
          rel="noopener noreferrer"
          href="http://www.eventloopllc.com"
          target="_blank"
          className="header"
          horizontal
          style={{ color: "#ccc", fontSize: "1em" }}
        >
          © 2020 Event Loop LLC, All Rights Reserved.
        </Divider>
      </div>
    </Segment>
  );
};

Footer.propTypes = {
  config: PropTypes.object,
};

export default Footer;
