import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Container, Menu, Responsive, Image } from "semantic-ui-react";
import logo from "./logo.png";
const DesktopContainer = ({ config, children }) => {
  const {
    speakers,
    sponsors,
    tickets,
    schedule,
    venue,
    videos,
    soldOut,
    links,
    workshop,
    diversityTickets,
  } = config;
  const items = { videos, speakers, schedule, workshop, venue, sponsors };

  return (
    <div>
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Menu fixed="top">
          <Container fluid>
            <Menu.Item
              name="home"
              as={Link}
              to="/"
              style={{ border: 0 }}
              className="logo borderless"
            >
              <Image
                src={logo}
                alt="React Loop - The First Chicago ReactJS Conference"
                size="small"
              />
            </Menu.Item>

            <Menu.Menu position="right">
              {/* {tickets && !soldOut && (
                <Menu.Item
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={links.TICKET_SALES}
                >
                  Buy Tickets
                </Menu.Item>
              )} */}
              {diversityTickets &&  (
                <Menu.Item
                name={'scholarship'}
                key={'scholarship'}
                as={Link}
                to={`/scholarship`}
                activeClassName="active"
                >
                  Scholarship
                </Menu.Item>
              )}
              {Object.keys(items).map((key, index) => {
                if (items[key]) {
                  return (
                    <Menu.Item
                      name={key}
                      key={key}
                      as={Link}
                      to={`/${key}`}
                      activeClassName="active"
                    >
                      {key}
                    </Menu.Item>
                  );
                }
                return null;
              })}
              <Menu.Item
                activeClassName="active"
                name="conduct"
                as={Link}
                to="/conduct"
              >
              </Menu.Item>
            </Menu.Menu>
          </Container>
        </Menu>
        <Container fluid style={{ marginTop: 72 }}>
          {children}
        </Container>
      </Responsive>
    </div>
  );
};

DesktopContainer.propTypes = {
  config: PropTypes.object,
  children: PropTypes.node,
};

export default DesktopContainer;
