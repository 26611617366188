import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Speaker from "../speaker/Speaker";
import shuffle from "../../utils/shuffle";
import { Container, Header, Card, Button, Icon } from "semantic-ui-react";

const FeaturedSpeakers = ({ speakers, config }) => (
  <Container
    style={{ paddingTop: 25, paddingBottom: 25 }}
    className="speakers-cards"
  >
    <Header as="h2" content="Speakers" />
    <Card.Group>
      {speakers
        .filter(s => s.featured && s.active)
        .map(speaker => {
          return (
            <Speaker
              featured
              key={speaker.name}
              speaker={speaker}
              url={config.schedule ? speaker : null}
            />
          );
        })}
    </Card.Group>
    {!config.speakers && (
      <p style={{ marginTop: 14, fontSize: 16 }}>
        More speaker announcements coming soon!
      </p>
    )}
    {config.speakers && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "25px 0 0 0",
          margin: "0 auto",
        }}
      >
        <Button icon size="medium" primary as={Link} to="/speakers">
          See all of the speakers here
          <Icon name="right arrow" />
        </Button>
      </div>
    )}
  </Container>
);

FeaturedSpeakers.propTypes = {
  speakers: PropTypes.array,
  config: PropTypes.object,
};

export default FeaturedSpeakers;
